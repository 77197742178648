:root {
  --background: ;
  --light-button: ;
  --dark-button: ;
  --light-text: ;
  --input-background: ;
}

body {
  margin: 0;
  background-color: var(--background);
  overflow-x: hidden;
}

h1 {
  font-size: 50px;
  margin: 5px;
  color: var(--light-text);
  font-family: "DotGothic16", sans-serif;
}

h3 {
  margin: 0px;
  margin-bottom: 5px;
  color: var(--light-text);
  font-family: "DotGothic16", sans-serif;
}

.container {
  display: flex;
  width: 100vw;
  height: 60vh;
  justify-content: top;
}

.containerFunction {
  display: flex;
  width: 100vw;
  height: 30vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.input-container {
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.textfield-container {
}

.input-submit {
  transition: transform 250ms;
}

.input-submit:hover {
  transform: scale(1.35, 1.35);
  cursor: pointer;
}

.inputField {
  margin: 20px;
}

.result {
  text-align: center;
  margin: 0px 10px 10px;
  font-size: 30px;
  font-family: "DotGothic16", sans-serif;
  color: #89d6fb;
}

.bestMatch {
  color: #0cb615;
}

.FunctionPicker-container {
  display: flex;
  cursor: pointer;
}

.FunctionPicker-button {
  border: 1px solid;
  border-radius: 3px;
  padding: 2px 15px 2px 15px;
  background-color: var(--dark-button);
  font-family: "DotGothic16", sans-serif;
  font-weight: bold;
  color: var(--light-text);
  border-color: black;
}

.FunctionPicker-button-active {
  background-color: var(--light-button);
  color: rgb(0, 0, 0);
}

.submit {
  border: 1px solid;
  font-family: "DotGothic16", sans-serif;
  font-weight: bolder;
  font-size: 20px;
  border-radius: 3px;
  background-color: var(--light-button);
  margin-bottom: 3px;
  cursor: pointer;
  width: 100%;
}

.margin-5 {
  margin-top: 5px;
}

.sortButton {
  width: 100%;
  background-color: var(--dark-button);
  border-radius: 3px;
  border: 1px solid;
  font-family: "DotGothic16", sans-serif;
  cursor: pointer;
  font-weight: bolder;
  text-align: center;
  display: block;
  height: 30px;
  font-size: 20px;
}

.sortButton-active {
  background-color: orangered;
}

.colourSelect {
  position: fixed;
  top: 0;
  right: 10px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100vw;
}

.footer-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: auto;
  align-self: center;
}

.footer-logo {
  align-self: center;
  margin-bottom: 3px;
}
